<template>
  <Popup ref="popupRef" width="610px" title="新建顾问分配" confirm-text="确认" @confirm="confirm" @close="resetForm">
    <el-form label-position="top">
      <el-form-item label="商户名称" required>
        <el-input placeholder="请输入商户名称"></el-input>
      </el-form-item>
      <el-form-item label="注册账号" required>
        <el-input placeholder="请输入注册账号"></el-input>
      </el-form-item>
      <el-form-item label="招聘类目">
        <el-input placeholder="请输入招聘类目"></el-input>
      </el-form-item>
      <el-form-item label="注册日期">
        <el-date-picker
          style="width:100%"
          v-model="form.registerDate"
          value-format="yyyy-MM-dd"
          type="date"
          placeholder="请选择注册日期">
        </el-date-picker>
      </el-form-item>
      <el-form-item label="商务顾问" required>
        <el-input placeholder="请输入商务顾问姓名"></el-input>
      </el-form-item>
      <el-form-item label="签约状态" required>
        <el-radio-group v-model="form.signState">
          <el-radio :label='0'>已签约</el-radio>
          <el-radio :label='1'>未签约</el-radio>
          <el-radio :label='2'>未合作</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="应用渠道" required>
        <el-input placeholder="请输入渠道名称"></el-input>
      </el-form-item>
    </el-form>
  </Popup>
</template>

<script>
import Popup from '@/components/Popup'
export default {
  components: {
    Popup
  },
  data() {
    return {
      form: {
        signState: 0,
        registerDate: ''
      }
    }
  },
  methods: {
    open(option) {
      this.$refs.popupRef.open()
    },
    resetForm() {

    },
    confirm() {

    }
  }
}
</script>

<style lang="scss" scoped>

</style>